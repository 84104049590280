import { differenceInSeconds } from "date-fns";
import schema from "../gql/animelist.graphql";
import { getStoredValueUnsafe } from "./cachehelper";
const ANILIST_API = "https://graphql.anilist.co";
const getAnilistResponse = async () => {
    const query = schema.loc.source.body;
    const options = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables: {}
        })
    };
    return await fetch(ANILIST_API, options)
        .then(res => res.json());
};
export const getAnilistAnimeList = async () => {
    const response = await getAnilistResponse();
    const outList = [];
    response.data.MediaListCollection.lists.forEach(list => {
        list.entries.forEach(entry => {
            outList.push(entry.media);
        });
    });
    return outList;
};
export const getSecondsSinceRequestDate = () => {
    const cache = getStoredValueUnsafe(ANILIST_CACHE_KEY);
    return differenceInSeconds(new Date(), new Date(cache.requestDate));
};
export const ANILIST_CACHE_KEY = "anilistdata";
