import { writable } from 'svelte/store';
export const animeStore = writable({
    loading: true,
    hideTop: false,
    hideBottom: true,
    anilist: [],
    modalAnime: null,
    filters: {
        text: "",
        year: "",
        season: "",
        current: false
    },
    years: [],
    seasons: []
});
