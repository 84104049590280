import { isFuture, isPast } from "date-fns";
export async function getStoredValue(value, onValueNotFound) {
    const storageValue = localStorage.getItem(value);
    let storage = !!storageValue ? JSON.parse(storageValue) : false;
    const expireDate = !!storage ? new Date(storage === null || storage === void 0 ? void 0 : storage.expire) : false;
    if (storage && expireDate && isFuture(expireDate)) {
        return storage;
    }
    else {
        storage = await onValueNotFound();
        localStorage.setItem(value, JSON.stringify(storage));
        return storage;
    }
}
export function getStoredValueUnsafe(value) {
    if (storedValueExists(value)) {
        return JSON.parse(localStorage.getItem(value));
    }
    else
        return null;
}
export function storedValueExists(value) {
    return !!localStorage.getItem(value);
}
export function storedValueExpired(value) {
    if (storedValueExists(value)) {
        const storedString = localStorage.getItem(value);
        const storedValue = JSON.parse(storedString);
        const expireDate = new Date(storedValue.expire);
        return isPast(expireDate);
    }
    return true;
}
